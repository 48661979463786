
const units = {
    "ALT": "U/L",
    "AST": "U/L",
    "Age": "y",
    "Anticoagulants": null,
    "Antihypert. drugs pre-stroke": null,
    "Antiplatelet drugs": null,
    "Atrial fibrillation": null,
    "Bilirubin": "µmol/L",
    "C-reactive protein": "mg/L",
    "Calcium": "mmol/L",
    "Chlore": "mmol/L",
    "Coronary artery disease": null,
    "Creatinine": "µmol/L",
    "Diabetes": null,
    "Diastolic blood pressure": "mmHg",
    "Erythrocytes": "10^12/L",
    "FiO2": "%",
    "Fibrinogen": "g/L",
    "GCS": null,
    "Glucose": "mmol/L",
    "HDL Cholesterol": "mmol/L",
    "HbA1c": "%",
    "Heart rate": "bpm",
    "Hematocrit": "%",
    "Hemoglobin": "g/L",
    "History of cerebrovascular events": null,
    "Hyperlipidemia": null,
    "Hypertension": null,
    "IAT timing": "[cat]",
    "INR": null,
    "IVT timing": "[cat]",
    "LDL": "mmol/L",
    "Lactate": "mmol/L",
    "Leucocytes": "10^9/L",
    "Lipid lowering drugs pre-stroke": null,
    "Mean blood pressure": "mmHg",
    "NIHSS": null,
    "Onset to admission time": "[cat]",
    "Oxygen saturation": "%",
    "PTT": "sec",
    "Peripheral artery disease": null,
    "Potassium": "mmol/L",
    "Prestroke disability (Rankin)": null,
    "Referral": null,
    "Respiratory rate": "/min",
    "Sex": null,
    "Smoking": null,
    "Sodium": "mmol/L",
    "Systolic blood pressure": "mmHg",
    "Temperature": "°C",
    "Thrombocytes": "10^9/L",
    "Total Cholesterol": "mmol/L",
    "Triglycerides": "mmol/L",
    "Urea": "mmol/L",
    "Wake up stroke": null,
    "Weight": "kg",
    "proBNP": "pg/mL"
}

export default units;